import { Injectable } from '@angular/core';
import * as env from "../../../../environments/environment";
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class FunctionsService {

  constructor(
      private afAuth: AngularFireAuth,
      private router: Router ) { }

  public getLocalDesc(codLocal, dataBase){
    if (dataBase != 'drrafael') {
      switch (codLocal) {
        case "0": return "Interno";
        case "1": return "Externo";
        default: return "Interno"
          break;
      }

    } else {
      switch (codLocal) {
        case "0": return "Bnu";
        case "1": return "BC";
        case "2": return "SP";
        case "3": return "RJ";
        default:
          break;
      }

    }
  }

  public formataData(data){
    if (data && data != ''){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
    }else{
      return '';
    }
  }

  public formataData2(data){
    if (data && data != ''){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY');
    }else{
      return '';
    }
  }
  public formataData3(data) {
    if (data && data != '') {
      return moment(data, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
    } else {
      return '';
    }
  }

  public formataData4(data){
    if (data && data != ''){
      return moment(data, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
    }else{
      return '';
    }
  }

  public convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              if (objs[item]) {
                let data = objs[item];
                data['id'] = item;
                data['uid'] = item;
                return data;
              }
            });
    }
    return [];
  }


  public minToHour(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }

  async getUserToken() {
    let token = await this.afAuth.idToken.pipe(first()).toPromise();
    return token;
  }

  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return
    }

    return base.base;
  }

  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = this.getDataBase();
    }
    if (base == 'agb') {
      base = 'agb-computadores';
    }
    let url = env.url_firebase.replace('###', base);
    return url;
  }

  formatDecimal(valor) {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  transformCelPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{1})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
      } else {
        return value;
      }
    }
    return '';
  }


  transformPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      } else {
        return value;
      }
    }
    return '';
  }

}
