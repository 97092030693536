import { HttpLoaderService } from './http-loader.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { PushNotification } from './shared/services/push-notification.service';
import { UserService } from './shared/services/user/user-service.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loadedData = false;
    public isLoading = true;

    constructor(
        private httpLoaderService: HttpLoaderService,
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private pushNotification: PushNotification
    ) {
        if (window.location.search == '?isMobile=true') {
            localStorage.setItem('isMobile', 'S');
        }
    }

    ngOnInit() {

        this.afAuth.authState.subscribe(async (user: firebase.User) => {
            let userData = await this.userService.getUserFromDataBase();
            if (userData && userData.id && (window as any).mobileToken) {
                this.pushNotification.sendToken(userData.id, (window as any).mobileToken).subscribe();
            }
        });
        let doLoader = true;
        this.httpLoaderService.isLoader().subscribe(isLoader => {
            doLoader = isLoader;
            if (!isLoader) {
                setTimeout(() => {
                    if (!doLoader) {
                        this.isLoading = isLoader;
                    }
                }, 300);
            } else {
                this.isLoading = isLoader;
            }
        });
    }
}
