export class User {

    public id: string = '';
    public username: string = '';
    public email: string = '';
    public adm: boolean;
    public master: boolean;
    public gestor: boolean;
    public tecnico: boolean;
    public nome: string = '';
    public ativo: boolean = true;
    public base: string = '';
    public primeiroLogon: boolean = true;
    public setor: string = '';
    public unidades: any[] = [];
    public colorCalendar: {
        secondary: string,
        primary: string
    } = {
        secondary: '#fc0505',
        primary: '#fc0505'
    }

    constructor() {
    }

}
