import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Http } from '@angular/http';

import * as env from "../../../../environments/environment";
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { FunctionsService } from '../utilsFunctions/functions.service';

@Injectable()
export class LogService {
  logsList: any;

  constructor(private db: AngularFireDatabase,
              private functions: FunctionsService,
              private router: Router,
              private http: Http,
              private afAuth: AngularFireAuth) {
 }

 getDataBase() {
   let base = JSON.parse(localStorage.getItem("system-base"));
   if (!base) {
     this.router.navigate(['/login']);
     return
   }

   return base.base;
 }

 async loadLogs(inicio, fim, tipo) {

  let token = await this.functions.getUserToken();
  let base = this.getDataBase();

  let mInicio = moment(inicio).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  let mFim = moment(fim).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

  let url = `${this.functions.getBaseURL()}/dados/logs/.json?orderBy="data"&startAt="${mInicio}"&endAt="${mFim}"&auth=${token}`;


  let data = await this.http.get(url).toPromise();
  return this.functions.convertObjToArray(data);
 }

  public comparaOS(oldOS, newOS){
    let log = [];
    if (oldOS.codCliente != newOS.codCliente) {
      let valores = ['Cliente', oldOS.codCliente, newOS.codCliente];
      log.push(valores);
      //let logJson = JSON.stringify(log);
      //console.log(JSON.stringify(log));
      //console.log(log);
      //let testeArray = JSON.parse(logJson);
    }
    if (oldOS.dataInicio != newOS.dataInicio) {

    }
    if (oldOS.dataFim != newOS.dataFim) {
      let valores = ['Data Fim', oldOS.dataFim, newOS.dataFim];
      log.push(valores);
    }
    if (oldOS.status != newOS.status) {

    }
    if (oldOS.local != newOS.local) {
      let valores = ['Local', this.getLocalDesc(oldOS.local), this.getLocalDesc(newOS.local)];
      log.push(valores);
    }
    if (oldOS.descricao != newOS.descricao) {
      let valores = ['Descrição', oldOS.descricao, newOS.descricao];
      log.push(valores);
    }
    if (oldOS.nomeTecnico != newOS.nomeTecnico) {
      let valores = ['Técnico', oldOS.nomeTecnico, newOS.nomeTecnico];
      log.push(valores);
    }
    if (oldOS.situacao != newOS.situacao) {
      let valores = ['Situação', oldOS.situacao, newOS.situacao];
      log.push(valores);
    }
    if (oldOS.esforco != newOS.esforco) {
      let valores = ['Esforço', oldOS.esforco, newOS.esforco];
      log.push(valores);
    }
    if (oldOS.solucao != newOS.solucao) {
      let valores = ['Solução', oldOS.solucao, newOS.solucao];
      log.push(valores);
    }
    if (oldOS.dataVencimento != newOS.dataVencimento) {
      let valores = ['Vencimento', oldOS.dataVencimento, newOS.dataVencimento];
      log.push(valores);
    }
    if (oldOS.dataEsforco != newOS.dataEsforco) {
      let valores = ['Data esforço', oldOS.dataEsforco, newOS.dataEsforco];
      log.push(valores);
    }
    if (oldOS.isentar != newOS.isentar) {
      let valores = ['Isentar', oldOS.isentar, newOS.isentar];
      log.push(valores);
    }
    if (oldOS.listaEsforco != newOS.listaEsforco) {
      /*let valores = ['Esforços', oldOS.listaEsforco, newOS.listaEsforco];
      log.push(valores);*/
    }

    let oldAtividades = [];
    let newAtividades = [];
    if (oldOS.atividades){
      oldAtividades = JSON.parse(oldOS.atividades);
    }
    if (newOS.atividades){
      newAtividades = JSON.parse(newOS.atividades);
    }
    if (oldAtividades.length > newAtividades.length){
      let valores = ['Removeu Atividade', oldAtividades.length, newAtividades.length];
      log.push(valores);
    }
    if (oldAtividades.length < newAtividades.length){
      let valores = ['Adicionou Atividade', oldAtividades.length, newAtividades.length];
      log.push(valores);
    }
    if (oldAtividades.length == newAtividades.length){
      if (oldAtividades.length > 0){
        oldAtividades.forEach((item, index) => {
          if (item.solucao != newAtividades[index].solucao){
            let valores = ['Alterou solução da Atividade', item.solucao, newAtividades[index].solucao];
            log.push(valores);
          }

          if (item.esforco != newOS.atividades[index].esforco){
            let valores = ['Alterou esforço da Atividade', item.esforco, newOS.atividades[index].esforco];
            log.push(valores);
          }
        });
      }
    }

    if (log.length > 0) {
      let valores = ['datalog', moment().format('DD/MM/YYYY HH:mm')];
      log.push(valores);
    }
    return log;
  }

  public getLocalDesc(codLocal){
    if (codLocal == 0){
      return "Interno";
    } else {
      return "Externo";
    }
  }

  public comparaUltimoContato(oldCliente, newCliente){
    let log = [];
    if (oldCliente.observContato != newCliente.observContato){
      let valores = ['Alterou descrição', oldCliente.observContato, newCliente.observContato];
      log.push(valores);
    }
    if (oldCliente.ultimoContato != newCliente.ultimoContato){
      let valores = ['Alterou data', oldCliente.ultimoContato, newCliente.ultimoContato];
      log.push(valores);
    }

    if (log.length > 0) {
      let valores = ['datalog', moment().format('DD/MM/YYYY HH:mm')];
      log.push(valores);
    }
    return log;
  }

  public comparaInventario(oldCliente, newCliente){

  }

  public addLog(data, acao, tipo) {
    this.afAuth.authState.subscribe((user: firebase.User) => {
      let date = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.logsList = this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/logs/`);
      if (tipo === "Despesas" || tipo === "Receitas") {
        let valores = moment(data.paidDate).format('DD-MM-YYYY');
        let preco = data.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        let dataBase = {
          Descricao :data.desc,
          'Data':valores, 
          'Tipo de Pagamento' :data.paymentType, 
          'Nome cliente': data.personData.name,
          'Valor': preco
        }
        this.logsList.push({usuario: user.email, acao: acao, codigo: dataBase.Descricao, tipo: tipo, chaveReg: data.id, registros: dataBase, data: date});
      } else {
        this.logsList.push({usuario: user.email, acao: acao, codigo: data.codigo ? data.codigo : '', tipo: tipo, chaveReg: data.id ? data.id : '', registros: data, data: date});
      }
    });
  }

  public addNewPostLog(data, acao, tipo) {
    this.afAuth.authState.subscribe((user: firebase.User) => {
      let date = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.logsList = this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/logs/`);
      this.logsList.push({usuario: user.email, acao: acao, codigo: data.codigo, tipo: tipo, registros: data, data: date});
    });
  }

}
