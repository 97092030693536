import { APP_ID, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { Http } from '@angular/http';
import { LogService } from '../logs/log.service';
import { CacheService } from '../cache/cache.service';
import { FunctionsService } from '../utilsFunctions/functions.service';
import { ConfigService } from '../config/config.service';
import { CashflowService } from "../cashflow/cashflow.service";
import { CustomerService } from '../customer/customer.service';
import { PushNotification } from "../push-notification.service";
import { UserService } from '../user/user-service.service';
import { StockService } from '../stock/stock.service';
import { EstimateService } from '../estimate/estimate.service';
import { Orcamento } from '../../../classes/orcamento';

@Injectable({
  providedIn: 'root'
})
export class OsService {
  private osAberta: any[] = [];
  private osFechada: any[] = [];
  openedOSCacheHash = '';
  closedOSCacheHash = '';
  atribAut: any;
  clickSaveCount: number;
  reservData: any[];

  constructor(
    private messageService: MessageService,
    private functions: FunctionsService,
    private logs: LogService,
    private cacheService: CacheService,
    private http: Http,
    private router: Router,
    private httpClient: HttpClient,
    private db: AngularFireDatabase,
    private estimateService: EstimateService,
    private configService: ConfigService,
    private cashflowService: CashflowService,
    private customerService: CustomerService,
    private pushService: PushNotification,
    private userService: UserService,
    private stockService: StockService
  ) {
    //setInterval(() => console.log('aaaa'), 2000);
  }

  clearList() {
    this.osAberta = [];
    this.osFechada = [];
  }

  async getOpenedById(id) {
    let token = await this.functions.getUserToken();
    let base = this.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/${id}/.json?auth=${token}`).toPromise();

    data = JSON.parse(data['_body']);
    if (data) {
      data['id'] = id;
    }
    return data;
  }

  async getClosedById(date, id) {
    let token = await this.functions.getUserToken();
    let base = this.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${date}/${id}/.json?auth=${token}`).toPromise();

    data = JSON.parse(data['_body']);
    data['id'] = id;
    return data;
  }

  async saveOpenedOS(os) {
    const base = await this.getDataBase();
    if (os.id) {
      let token = await this.functions.getUserToken();

      let osExist = await this.getOpenedById(os.id);
      if (!osExist) {
        this.message('Esta OS foi alterada por outro usuário e não está mais ABERTA. Localize ela nas OS encerradas e faça a alteração novamente.', 'error', 'ATENÇÃO');
        return false;
      } else {
        await this.http.patch(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/${os.id}.json?auth=${token}`, os).toPromise();
        this.logs.addLog(os, "Alterou", "OS")
      }
    } else {

      if (base === "arber" || base === "macler" || base === "agb-os-dev") {
        let tecStatus = await this.configService.getTecStatus();
        if (!tecStatus) {
          /* ENVIAR EMAIL */
          // let listUser
          let desc = `Descrição da ordem: ` + os.descricao;
          let user = await this.userService.getUserFromDataBase();
          desc += `<br><br>Usuário solicitante: ${user.nome}`;
          desc += `<br><br>Setor: ${user.setor && user.setor !== '' ? user.setor : 'Usuário sem setor'}`;

          let email = 'felipe.santos.ofic@gmail.com';
          if (base == 'agb-os-dev') {
            email = `${user.email}`;
          }
          let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
          let body = {
            from: `checklist@agbcomputadores.com.br`,
            email: email,
            message: desc,
            subject: `Nova OS ${base == 'arber' ? 'ARBER' : 'MACLER'} - ${os.codigo}`
          }
          const headers = new HttpHeaders().set(
            "Content-Type",
            "application/json"
          );
          let options = { headers };
          await this.httpClient.post(url, body, options).toPromise();
        }
      }
      let token = await this.functions.getUserToken();
      let data = await this.http.post(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/.json?auth=${token}`, os).toPromise();

      data = JSON.parse(data['_body']);
      os.id = data['name'];

      let users = await this.userService.getActiveUsers();
      users = users.filter((item) => item.master);
      let ids = users.map((item) => item.id);
      this.pushService.sendPushNotification(ids, 'OS Criada', `A OS ${os.codigo} foi criada.`).subscribe();

      this.logs.addLog(os, "Criou", "OS");
      return data['name'];
    }
    this.clearList();
    this.cacheService.invalidateCache(this.cacheService.openedOSType);

    return true;
  }

  async saveProducts(produtosUtil, ordemServicoKey) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/produtosUtil/${ordemServicoKey}.json?auth=${token}`, produtosUtil).toPromise();
  }

  async loadProducts(ordemServicoKey) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/produtosUtil/${ordemServicoKey}.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveClosedOS(date, os) {
    if (os.id) {
      let token = await this.functions.getUserToken();
      let base = this.getDataBase();
      let osDataFim = moment(os.dataFim).format("YYYY-MM-DD");
      if (date != osDataFim) {
        await this.http.post(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${osDataFim}/.json?auth=${token}`, os).toPromise();
        await this.http.delete(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${date}/${os.id}/.json?auth=${token}`).toPromise();
        this.logs.addLog(os, "Alterou", "OS")
      } else {
        await this.http.patch(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${date}/${os.id}.json?auth=${token}`, os).toPromise();
        this.logs.addLog(os, "Alterou", "OS")
      }
    }
    this.clearList();
    this.cacheService.invalidateCache(this.cacheService.openedOSType);
  }

  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return
    }

    return base.base;
  }

  public async deleteOS(item, opened) {
    if (confirm("Deseja remover a OS?")) {
      this.logs.addLog(item, "Excluiu", "OS");
      let token = await this.functions.getUserToken();
      await this.http.post(`${this.functions.getBaseURL()}/dados/ordemServicoExcluidas/${item.id}/.json?auth=${token}`, item).toPromise();

      if (item.id) {
        if (opened) {
          await this.http.delete(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/${item.id}/.json?auth=${token}`).toPromise();
          this.cacheService.invalidateCache(this.cacheService.openedOSType);
          this.clearList();
          return true;
        } else {
          let dataFimFormatada = moment(item.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
          await this.http.delete(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${dataFimFormatada}/${item.id}/.json?auth=${token}`).toPromise();
          this.cacheService.invalidateCache(this.cacheService.closedOSType);
          this.clearList();
          return true;
        }
      }
    } else {
      return false;
    }
  }

  async closeOS(item, osProducts = undefined) {
    let osExist = await this.getOpenedById(item.id);
    if (!osExist) {
      this.message('Esta OS foi encerrada por outro usuário. Localize ela nas OS encerradas e faça novamente a alteração caso necessário.', 'error', 'ATENÇÃO');
      return false;
    }
    let incomeWhenCloseOS = await this.configService.loadIncomeWhenCloseOS();
    if (incomeWhenCloseOS && !item.isentar) {
      let value = 0;
      let desc = '';
      if (typeof(item.situacao) !== 'string') {
        desc = item.situacao.map(situacao => situacao.situacao ).join(', ');
        item.situacao.forEach((rsp:any) => {
          if (rsp.valor && isNaN(rsp.valor)) {
            rsp.valor.replace(',', '.');
          }
          value += parseInt(rsp.valor);
        })
      } else {
        value = await this.configService.situationWithValue(item.situacao);
        desc = item.situacao;
      }
      if (value) {
        let customer = await this.customerService.getCustomerDataByCode(item.codCliente);
        let date = moment();
        this.cashflowService.save(1, date.month() + 1, date.year(), {
          desc: desc,
          account: item.account,
          type: item.nomeTecnico,
          value: +value,
          date: new Date(),
          dueDate: new Date(),
          paymentType: item.paymentType,
          paid: item.paymentType ? true : false,
          paidDate: new Date(),
          paidValue: item.paymentType ? +value : 0,
          repeat: false,
          repeatType: 'monthly',
          repeatTime: 0,
          repeatInstallmentNumber: -1,
          repeatInstallmentTotal: -1,
          personData: {
            code: customer.id,
            name: customer.nomeFantasia
          },
          obs: item.titulo
        });

        item['savedIncome'] = true;
      } else {
        item['savedIncome'] = false;
      }
    } else {
      item['savedIncome'] = false;
    }

    this.logs.addLog(item, "Encerrou", "OS");
    let dataFimFormatada = moment(item.dataFim, 'yyyy-MM-DDTHH:mm').format('YYYY-MM-DD');

    if (item.id) {
      let token = await this.functions.getUserToken();

      try {
        await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${dataFimFormatada}/${item.id}/.json?auth=${token}`, item).toPromise();
      } catch (error) {
        console.log(error);
        this.logs.addLog(item, "ERRO no POST", "OS");
      }
      try {
        await this.http.delete(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/${item.id}/.json?auth=${token}`).toPromise();
      } catch (error) {
        console.log(error);
        this.logs.addLog(item, "ERRO no DELETE", "OS");
      }

      let base = this.getDataBase();
      if ((base == 'agb' || base == 'agb-os-dev') && osProducts && osProducts.length > 0 && !item.reabriuFlag) {
        let customer = await this.customerService.getCustomerDataByCode(item.codCliente);
        let text = `A OS ${item.codigo} foi encerrada e foi utilizado um ou mais produtos no atendimento:<br>`;
        osProducts.map((result) => {
          text += `Código: ${result.codigo} - ${result.desc} - Qtd: ${result.qtdUtil} - Valor de Venda: R$${this.functions.formatDecimal(result.valorVenda)}<br>`;
        })
        text += `<br>Técnico responsável: ${item.nomeTecnico}<br>`;
        text += `Cliente: ${customer.nomeFantasia}<br>`;

        let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
        let body = {
          from: `no.reply.dgsys@gmail.com`,
          email: `dev@dgsys.com.br ${base == 'agb' ? ';atendimento@agbcomputadores.com.br;administrativo@agbcomputadores.com.br' : ''}`,
          message: text,
          subject: `OS Encerrada - ${item.codigo}`
        }

        const headers = new HttpHeaders().set(
          "Content-Type",
          "application/json"
        );
        let options = { headers }
        await this.httpClient.post(url, body, options).toPromise()
          .then(() => {
          })
          .catch((error) => {
            alert("ERRO!");
          });
      }
      if ((base == 'agb' || base == 'agb-os-dev') && (item.pendencia !== '' && item.pendencia !== undefined) && !item.reabriuFlag) {
        item.pendencia = item.pendencia.replace(/\n/g, "<br>");
        let customer = await this.customerService.getCustomerDataByCode(item.codCliente);
        let text = `A OS ${item.codigo} foi encerrada e foi informado à seguinte pendência:<br>`;
        text += `${item.codigo}<br>`;
        text += `Cliente: ${customer.nomeFantasia}<br>`;
        text += `<br>Técnico responsável: ${item.nomeTecnico}<br>`;
        text += `<br>Pendência: ${item.pendencia}<br>`;
        let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
        let body = {
          from: `no.reply.dgsys@gmail.com`,
          email: `${base == 'agb' ? 'suporte@agbcomputadores.com.br' : 'dev@dgsys.com.br'}`,
          message: text,
          subject: `OS Encerrada - ${item.codigo}`
        }

        const headers = new HttpHeaders().set(
          "Content-Type",
          "application/json"
        );
        let options = { headers }
        await this.httpClient.post(url, body, options).toPromise()
          .then(() => {
          })
          .catch((error) => {
            alert("ERRO!");
          });
      }
    }

    this.clearList();
    this.cacheService.invalidateCache(this.cacheService.closedOSType);
    this.cacheService.invalidateCache(this.cacheService.openedOSType);

    return true;
  }

  async reOpenOS(item) {
    this.logs.addLog(item, "Reabriu", "OS");
    let dataFimFormatada = moment(item.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
    item.status = 0;
    item.reabriuFlag = true;
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/${item.id}/.json?auth=${token}`, item).toPromise();
    await this.http.delete(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${dataFimFormatada}/${item.id}/.json?auth=${token}`).toPromise();

    this.clearList();
    this.cacheService.invalidateCache(this.cacheService.closedOSType);
    this.cacheService.invalidateCache(this.cacheService.openedOSType);
  }

  async loadOpenedOS() {
    //if (this.osAberta && this.osAberta.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.openedOSType, this.openedOSCacheHash)) {
    this.osAberta = [];
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/abertas/.json?auth=${token}`).toPromise();
    this.osAberta = this.functions.convertObjToArray(data);
    //this.openedOSCacheHash = await this.cacheService.getCacheHash(this.cacheService.openedOSType);
    //} else {
    //this.osAberta = [];
    //}

    return this.osAberta;
  }

  async loadAllClosedOS() {
    //if (this.osFechada.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.closedOSType, this.closedOSCacheHash)) {
    this.osFechada = [];
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/.json?auth=${token}`).toPromise();
    let list = this.functions.convertObjToArray(data);
    list.forEach((item) => {
      Object.keys(item).map((key) => {
        if (key != 'id' && key != 'uid') {
          let data = item[key];
          data['id'] = key;
          data['closed'] = true;
          this.osFechada.push(data);
        }
      });
    });
    this.closedOSCacheHash = await this.cacheService.getCacheHash(this.cacheService.closedOSType);
    //}

    return this.osFechada;
  }

  async loadClosedOSByDays(numberOfDays) {
    let token = await this.functions.getUserToken();
    const promises = [];
    let today = moment();
    for (let i = 0; i < numberOfDays; i++) {
      promises.push(this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${today.format('YYYY-MM-DD')}/.json?auth=${token}`).toPromise());
      today.subtract(1, 'd');
    }

    let osList = [];
    let data = await Promise.all(promises);
    data.forEach((item) => {
      let array = this.functions.convertObjToArray(item);
      osList = osList.concat(array);
    })

    return osList;
  }

  async loadClosedOSInterval(start, end, n?:number) {
    let token = await this.functions.getUserToken();
    let base = this.getDataBase();
    const promises = [];

    let dateStart = moment(start);
    let dateEnd = moment(end);
    if (!n) dateStart.add(-n, 'd')


    while (dateStart.isSameOrBefore(dateEnd)) {
      promises.push(this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${dateStart.format('YYYY-MM-DD')}/.json?auth=${token}`).toPromise());
      dateStart.add(1, 'd');
    }

    let osList = [];
    let data = await Promise.all(promises);
    data.forEach((item) => {
      let array = this.functions.convertObjToArray(item);
      osList = osList.concat(array);
    })

    return osList;
  }

  async loadOneWeekClosedOS() {
    return await this.loadClosedOSByDays(7);
  }
  async loadTwoWeekClosedOS() {
    return await this.loadClosedOSByDays(14);
  }
  async loadOneMonthClosedOS() {
    return await this.loadClosedOSByDays(30);
  }
  async loadTwoMonthClosedOS() {
    return await this.loadClosedOSByDays(60);
  }

  message(msg, type = 'warn', summary = 'Atenção', time = 5000) {
    this.messageService.add({ severity: type, summary: summary, detail: msg, life: time });
  }

  async getClosedByCliente(startDate, endDate, customer) {
    let token = await this.functions.getUserToken();
    let base = this.getDataBase();
    let data = [];
    let auxEndData = moment(endDate).add(1, 'days').format('YYYY-MM-DD')
    while (startDate != auxEndData) {
      let aux: any = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/concluidas/${startDate}/.json?auth=${token}`).toPromise();
      startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
      aux = JSON.parse(aux['_body']);
      if (aux) {
        let values: any = Object.values(aux)
        values.forEach(element => {
          if (element.codCliente == customer.codCliente) {
            data.push(element)
          }
        });
      }
    }
    return data;
  }

  //Atribuição automática
  async salvaAtrib(atrib) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/atribuicao/.json?auth=${token}`, atrib).toPromise();

  }

  async getAllAtrib() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/atribuicao/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.atribAut = data;

    return this.atribAut;
  }

  //Reserva
  async salvaReserv(atrib) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/dataReservada/datas/.json?auth=${token}`, atrib).toPromise();

  }

  async getAllReserv() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/dataReservada/datas/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.reservData = data;

    return this.reservData;
  }

  async salvaCidade(cidades) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/ordemServico/dataReservada/cidades/.json?auth=${token}`, cidades).toPromise();

  }

  async getAllCidade() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/ordemServico/dataReservada/cidades/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);

    return data;
  }

  async abrirOrcamento(customerData, produtosUtilOs, nomeTecnico) {
    let orcamento: Orcamento = new Orcamento();

    try {
      orcamento.codigoCliente = customerData.codCliente;
      orcamento.tipoPessoa = customerData.tipoPessoa;
      orcamento.emailCliente = customerData.email;
      orcamento.contatoCliente = '';
      orcamento.colaborador = nomeTecnico;
      orcamento.telefonCliente = customerData.telefone;
      orcamento.celularCliente = customerData.celular;
      orcamento.openAuto = true;
      if (produtosUtilOs.length > 0) {
        for (let i = 0; i < produtosUtilOs.length; i++) {
          orcamento.itemsOrcamento.push({
            codigo: produtosUtilOs[i].codigo,
            descProduto: produtosUtilOs[i].desc,
            qtd: produtosUtilOs[i].qtdUtil,
            valorUnitario: produtosUtilOs[i].valorVenda,
            prazoEntrega: 0,
            input: false
          });
        }
      }

      let savedData = await this.estimateService.saveEstimate(orcamento);
      return savedData.codigo;
    } finally {
      this.clickSaveCount = 0;
    }
  }

}
