import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PushNotification {

    constructor(
        private http: HttpClient
    ) { }

    public sendToken(userId: string, token: string) {
        const headers = new HttpHeaders({ 'x-api-key': '8b7c5058-1c63-4d91-b556-52a3be741e92' });
        return this.http.post(`${environment.pushApi}/dev/device`, {
            userId: userId,
            name: navigator.userAgent,
            token: token
        }, {
            headers: headers
        });
    }

    public sendPushNotification(users: string[], title: string, message: string) {
        const headers = new HttpHeaders({ 'x-api-key': '8b7c5058-1c63-4d91-b556-52a3be741e92' });
        return this.http.post(`${environment.pushApi}/dev/push_notification`, {
            users: users,
            title: title,
            body: message
        }, {
            headers: headers
        });
    }
}
