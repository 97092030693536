import { Injectable } from '@angular/core';

import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import * as env from "../../../../environments/environment";
import { FunctionsService } from '../utilsFunctions/functions.service';
import { CustomerService } from '../customer/customer.service';
import { UserService } from '../user/user-service.service';
import { LogService } from '../logs/log.service';
import { ConfigService } from '../config/config.service';
import { Orcamento } from '../../../classes/orcamento';
import { CacheService } from '../cache/cache.service';
import { TranslationService } from "../translation/translation.service";
import { isNgTemplate } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  openedOrc = [];
  closedOrc = [];
  customerInOpenedOrc = [];
  customerInClosedOrc = [];
  openedOrcCacheHash = '';
  closedOrcCacheHash = '';
  motivoRefuse: any;
  opcaoAbertura: any;
  opcaoPrazo: any;
  titulo4;
  titulo3;
  titulo2;
  titulo1;
  translate: any;
  totalValue: number;
  tipoDaMoeda: any;

  constructor(private userService: UserService,
    private customerService: CustomerService,
    private functions: FunctionsService,
    private configService: ConfigService,
    private logService: LogService,
    private cacheService: CacheService,
    private translateService: TranslationService,
    private http: Http,
    private http2: HttpClient) {
  }

  clearList() {
    this.openedOrc = [];
    this.closedOrc = [];
  }

  async loadAllOrc(clientes?) {
    let data = await this.loadOpenedOrc(clientes);
    data = data.concat(await this.loadApprovedOrc(clientes));
    data = data.concat(await this.loadClosedOrc(clientes));
    data = data.concat(await this.loadRejectedOrc(clientes));

    data.sort((a, b) => {
      if (moment(a.dataGerado, '').isAfter(moment(b.dataGerado))) {
        return -1;
      } else {
        if (moment(b.dataGerado, '').isAfter(moment(a.dataGerado))) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    return Object.assign([], data);
  }

  async loadAprovedInterval(clientes?, dataIni?, dataFim?) {
    let data = await this.loadApprovedOrc(clientes);
    data = data.filter((itm) =>
      itm.codigoCliente === clientes['0'].codCliente);
    let result = [];

    data.forEach((a) => {
      if (moment(a.dataGerado).isSameOrAfter(dataIni) && moment(a.dataGerado).isSameOrBefore(dataFim)) {
        result.push(a);
      }
    });

    return result;
  }

  async loadOpenedOrc(clientes?) {
    if (this.openedOrc.length == 0) {
      let token = await this.functions.getUserToken();
      let base = this.userService.getDataBase();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/aberto/.json?auth=${token}`).toPromise();
      this.openedOrc = this.functions.convertObjToArray(data);

      for (let i = 0; i < this.openedOrc.length; i++) {
        let cliente = clientes.find(x => x.codCliente == this.openedOrc[i].codigoCliente);
        if (cliente) {
          this.openedOrc[i]['nomeCliente'] = cliente.nomeFantasia;
          this.openedOrc[i]['contato'] = cliente.contato;
          this.openedOrc[i]['telefone'] = cliente.telefone;
          this.openedOrc[i]['celular'] = cliente.celular;
          this.openedOrc[i]['cidade'] = cliente.cidade;
          this.openedOrc[i]['valorTotal'] = this.getValorTotal(this.openedOrc[i]);//.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          if (this.customerInOpenedOrc.indexOf(cliente.nomeFantasia) == -1) {
            this.customerInOpenedOrc.push(cliente.nomeFantasia);
          }
        } else {
          this.openedOrc.splice(i, 1);
          i--;
        }
      }

      this.openedOrc.sort((a, b) => {
        if (moment(a.dataGerado, '').isAfter(moment(b.dataGerado))) {
          return -1;
        } else {
          if (moment(b.dataGerado, '').isAfter(moment(a.dataGerado))) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      this.openedOrcCacheHash = await this.cacheService.getCacheHash(this.cacheService.openedOrcType);
    }

    return Object.assign([], this.openedOrc);
  }

  public async loadClosedOrc(clientes?) {
    if (this.closedOrc.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.closedOrcType, this.closedOrcCacheHash)) {
      let token = await this.functions.getUserToken();
      let base = this.userService.getDataBase();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/encerrado/.json?auth=${token}`).toPromise();
      this.closedOrc = this.functions.convertObjToArray(data);

      for (let i = 0; i < this.closedOrc.length; i++) {
        let cliente = clientes.find(x => x.codCliente == this.closedOrc[i].codigoCliente);
        if (cliente) {
          this.closedOrc[i]['nomeCliente'] = cliente.nomeFantasia;
          this.closedOrc[i]['contato'] = cliente.contato;
          this.closedOrc[i]['telefone'] = cliente.telefone;
          this.closedOrc[i]['celular'] = cliente.celular;
          this.closedOrc[i]['cidade'] = cliente.cidade;
          this.closedOrc[i]['valorTotal'] = await this.getValorTotal(this.closedOrc[i]);//.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          if (this.customerInClosedOrc.indexOf(cliente.nomeFantasia) == -1) {
            this.customerInClosedOrc.push(cliente.nomeFantasia);
          }
        }
      }

      this.closedOrc.reverse();

      this.closedOrcCacheHash = await this.cacheService.getCacheHash(this.cacheService.closedOrcType);
    }
  }

  async loadApprovedOrc(clientes?) {
    await this.loadClosedOrc(clientes);
    return Object.assign([], this.closedOrc.filter((orc) => orc.status == 'Aprovado'));
  }

  async loadRejectedOrc(clientes?) {
    await this.loadClosedOrc(clientes);
    return Object.assign([], this.closedOrc.filter((orc) => orc.status == 'Rejeitado' || orc.status == 'Refazer'));
  }

  async loadById(id, status): Promise<Orcamento> {
    let orc: Orcamento = undefined;
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/${status}/${id}/.json?auth=${token}`).toPromise();
    orc = JSON.parse(data['_body']);

    if (orc) {
      orc['id'] = id;
      let cliente = await this.customerService.getCustomerDataByCode(orc['codigoCliente']);
      if (cliente) {
        orc['nomeCliente'] = cliente.nomeFantasia;
        orc['contato'] = cliente.contato;
        orc['telefone'] = cliente.telefone;
        orc['celular'] = cliente.celular;
        orc['cidade'] = cliente.cidade;
        orc['valorTotal'] = this.getValorTotal(orc);//.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    }

    return orc;
  }

  getValorTotal(orc) {
    if (this.hasItemWithValue(orc)) {
      let total = 0;
      let base = this.userService.getDataBase();
      if (orc.itemsOrcamento) {
        orc.itemsOrcamento.forEach((item) => {
          if (base !== 'drrafael') {
            if (item.valorUnitario && item.qtd) {
              total += item.valorUnitario * item.qtd;
            }
          } else {
            total += item.valorUnitario;
          }
        });
      }

      return total;
    } else {
      return orc.valorTotal ? orc.valorTotal : 0;
    }
  }

  getCustoTotal(orc, desc) {

    let total = 0;
    let base = this.userService.getDataBase();
    if (orc.itemsOrcamento) {
      orc.itemsOrcamento.forEach((item) => {
        if (base == 'dgsys' || base == 'agb-os-dev') {
          if (item.valorCusto && item.qtd) {
            total += item.valorCusto * item.qtd;
          }
        } else {
          total += item.valorCusto;
        }
      });
    }
    if (total) {
      return total;
    } else {
      return orc.valorCusto;
    }
  }

  hasItemWithValue(orc) {
    let has = false;
    let base = this.userService.getDataBase();
    if (orc.itemsOrcamento) {
      orc.itemsOrcamento.forEach((item) => {
        if (item.valorUnitario > 0) {
          has = true;
        }
      });
    }

    return has;
  }

  getQtdTotal(orc) {
    let total = 0;
    if (orc.itemsOrcamento) {
      orc.itemsOrcamento.forEach((item) => {
        if (item.qtd) {
          total += item.qtd;
        }
      });
    }

    return total;
  }

  async duplicateEstimate(data) {
    data.status = 'Orçado';
    data['id'] = undefined;
    await this.saveEstimate(data); 
  }

  async approving(data) {
    if (data.id) {
      let oldData = await this.loadById(data.id, data.status == 'Orçado' ? 'aberto' : 'encerrado');
      if (!oldData) {
        if (data.status == 'Aprovado') {
          return true;
        }
      }
    }
    return false;
  }

  async saveEstimate(data, emailColaborador?) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let customerProspectData = await this.customerService.getProspectDataByCode(data.codigoCliente);
    if (!customerProspectData) {
      customerProspectData = await this.customerService.getCustomerDataByCode(data.codigoCliente);
    }

    if (!data.id) { // NOVO
      data.dataGerado = moment().format('YYYY-MM-DDTHH:mm');
      data.codigo = await this.configService.getNewLastEstimateCode();
      let newData = await this.http.post(`${this.functions.getBaseURL()}/dados/orcamento/${data.status == 'Orçado' ? 'aberto' : 'encerrado'}/.json?auth=${token}`, data).toPromise();
      this.cacheService.invalidateCache(this.cacheService.openedOrcType);
      data.id = JSON.parse(newData['_body']).name;

      customerProspectData.ultimoOrc = data.dataGerado;
      if (!customerProspectData.qtdOrc) {
        customerProspectData.qtdOrc = 0;
      }
      customerProspectData.qtdOrc += 1;
      if (customerProspectData.prospect) {
        await this.customerService.saveProspect(customerProspectData);
      } else {
        await this.customerService.saveCustomer(customerProspectData);
      }
    } else {
      if (base == 'agb' || base == 'agb-os-dev') {
        data.dataAlteracao = moment().format('YYYY-MM-DDTHH:mm');
      }

      let oldData = await this.loadById(data.id, data.status == 'Orçado' ? 'aberto' : 'encerrado');
      if (oldData) {
        data.dataAlteracao = moment().format('YYYY-MM-DDTHH:mm');
        await this.http.put(`${this.functions.getBaseURL()}/dados/orcamento/${data.status == 'Orçado' ? 'aberto' : 'encerrado'}/${data.id}.json?auth=${token}`, data).toPromise();
        if (data.status == 'Orçado') {
          this.cacheService.invalidateCache(this.cacheService.openedOrcType);
        } else {
          this.cacheService.invalidateCache(this.cacheService.closedOrcType);
        }
      } else {
        if (data.status == 'Orçado') {
          // Está reabrindo
          let newData = await this.http.post(`${this.functions.getBaseURL()}/dados/orcamento/aberto/.json?auth=${token}`, data).toPromise();
          this.cacheService.invalidateCache(this.cacheService.openedOrcType);
          await this.deleteEstimate(data.id, 'encerrado');
          data.id = JSON.parse(newData['_body']).name;
        } else {
          // Está fechando
          if (data.status == 'Aprovado') {
            // MUDAO PROSPECT PARA CLIENTE
            if (customerProspectData) {
              if (!customerProspectData.qtdOrcAprovado) {
                customerProspectData.qtdOrcAprovado = 0;
              }
              customerProspectData.qtdOrcAprovado += 1;
              customerProspectData.ultimaOrcAprovado = moment().format('YYYY-MM-DDTHH:mm');
              if (customerProspectData.prospect) {
                if (customerProspectData.id) {
                  customerProspectData.prospect = false;
                  await this.customerService.saveCustomer(customerProspectData);
                  await this.customerService.removeProspect(customerProspectData, false);
                } else {
                  alert('ATENÇÃO - Orçamento APROVADO com sucesso, mas não foi possível converter o PROSPECT para CLIENTE.');
                }
              } else {
                await this.customerService.saveCustomer(customerProspectData);
              }
            }
          }
          let newData = await this.http.post(`${this.functions.getBaseURL()}/dados/orcamento/encerrado/.json?auth=${token}`, data).toPromise();
          this.cacheService.invalidateCache(this.cacheService.closedOrcType);
          await this.deleteEstimate(data.id, 'aberto');
          data.id = JSON.parse(newData['_body']).name;
        }
      }
    }
    if (data.status == "Rejeitado" || data.status == 'Aprovado') {
      data.emailColaborador = emailColaborador;
      this.enviaEmail(data, base, customerProspectData);
    }
    await this.logService.addLog(data, "Alterou", "Orçamento");
    this.clearList();

    return data;
  }

  async enviaEmail(item, base, customer) {
    if (base == 'agb' || base == 'agb-os-dev') {
      let dataGerado = moment(item.dataGerado).format('DD/MM/YYYY');
      let text = `Orçamento ${item.codigo} passou para o status ${item.status} - ${moment().format('DD/MM/YYYY')}<br>`;
      text += `Cliente: ${customer.nomeFantasia}<br><br><hr><br>`;
      text += `<br>Número do orçamento: ${item.codigo}<br>`;
      text += `<br>Status: ${item.status}<br>`;
      text += `<br>Data(Aprovação/Rejeição): ${dataGerado}<br>`;
      text += `Produtos orçados<br>`;
      item.itemsOrcamento.map((result) => {
        text += `Código: ${result.codigo} - ${result.descProduto} - Qtd: ${result.qtd}<br>`;
      });
      let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
      let body = {
        from: `no.reply.dgsys@gmail.com`,
        email: `${base == 'agb' ? `atendimento@agbcomputadores.com.br;${item.emailColaborador.email}` : 'dev@dgsys.com.br'}`,
        message: text,
        subject: `Orçamento - ${item.codigo} - ${item.status}`
      }

      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/json"
      );
      let options = { headers }
      await this.http2.post(url, body, options).toPromise()
        .then(() => {
        })
        .catch((error) => {
          alert("ERRO!");
        });
    }
  }

  async sendEmail(email: {emails: string, subject: string, text: string}) {
    let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
    let body = {
      from: `no.reply.dgsys@gmail.com`,
      email: email.emails,
      message: email.text,
      subject: email.subject
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let options = { headers }
    await this.http2.post(url, body, options).toPromise()
      .then(() => {
      })
      .catch((error) => {
        alert("ERRO!");
      });
  }

  async deleteEstimate(id, status) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    await this.http.delete(`${this.functions.getBaseURL()}/dados/orcamento/${status}/${id}/.json?auth=${token}`).toPromise();
    if (status == 'aberto') {
      this.cacheService.invalidateCache(this.cacheService.openedOrcType);
    } else {
      this.cacheService.invalidateCache(this.cacheService.closedOrcType);
    }
    this.clearList();
  }

  async getListCustomerOpenedOrc() {
    return this.customerInOpenedOrc;
  }

  async getListCustomerClosedOrc() {
    return this.customerInClosedOrc;
  }

  formataDecimal(valor) {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  public incLineTop(doc, currentValue, valueInc) {
    let database = this.userService.getDataBase();
    currentValue += valueInc;
    if (((database == 'dgsys' || database == 'agb') && currentValue >= 10) || currentValue >= 11) {

      if (database == 'dgsys') {
        var img = new Image();
        img.src = '../../../../assets/images/dg-produtos/Gestão-de-Bens.jpg';
        doc.addImage(img, 'PNG', 0.75, 11, 1.1, 0.6, '', 'FAST');

        img.src = '../../../../assets/images/dg-produtos/Gestão-Fácil.jpg';
        doc.addImage(img, 'PNG', 2.35, 11, 1.1, 0.6, '', 'FAST');

        img.src = '../../../../assets/images/dg-produtos/Personalização-Fácil.jpg';
        doc.addImage(img, 'PNG', 3.7, 11, 1.1, 0.6, '', 'FAST');

        img.src = '../../../../assets/images/dg-produtos/Produção-Fácil.jpg';
        doc.addImage(img, 'PNG', 5.05, 11, 1.1, 0.6, '', 'FAST');

        img.src = '../../../../assets/images/dg-produtos/RH-Fácil.jpg';
        doc.addImage(img, 'PNG', 6.4, 11, 1.1, 0.6, '', 'FAST');
      } else {
        if (database == 'agb') {
          var img = new Image();
          img.src = '../../../../assets/images/agb-produtos/azure.png';
          doc.addImage(img, 'PNG', 0.55, 10.9, 1.1, 0.8, '', 'FAST');

          img.src = '../../../../assets/images/agb-produtos/bkp.png';
          doc.addImage(img, 'PNG', 1.75, 10.9, 1.1, 0.8, '', 'FAST');

          img.src = '../../../../assets/images/agb-produtos/firewall.png';
          doc.addImage(img, 'PNG', 2.9, 10.9, 1.1, 0.8, '', 'FAST');

          img.src = '../../../../assets/images/agb-produtos/moni.png';
          doc.addImage(img, 'PNG', 4.15, 10.9, 1.1, 0.8, '', 'FAST');

          img.src = '../../../../assets/images/agb-produtos/office.png';
          doc.addImage(img, 'PNG', 5.45, 10.9, 1.1, 0.8, '', 'FAST');

          img.src = '../../../../assets/images/agb-produtos/powerbi.png';
          doc.addImage(img, 'PNG', 6.65, 10.9, 1.1, 0.8, '', 'FAST');
        }
      }

      doc.addPage();
      currentValue = 0.6;
    }
    return currentValue;
  }

  private measureText(str, fontSize = 10) {
    if (str === "") {
      return 0;
    }

    const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625]
    const avg = 0.5279276315789471
    return str
      .split('')
      .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .reduce((cur, acc) => acc + cur) * fontSize
  }

  public async exportClientesPDF(orcamento, dadosCliente, isMobile) {
    this.export_html_pdf(orcamento, dadosCliente);
  }

  public getItensTableRafael(orcamento, corInfo, corTitulo, database) {
    let html;
    html = `<table style="width: 100%; ">
              <tr style='background-color: ${corTitulo}; text-align: center; color: white;'>
                <td colspan="6" style="font-size: 16px;">${this.titulo2}</th>
              </tr>
              <tr style='background-color: ${corInfo}; line-height: 20px;'>
                  <td>${this.translate.hasOwnProperty('Código') ? this.translate['Código'] : 'Código'}</td>
                  <td>${this.translate.hasOwnProperty('Produto') ? this.translate['Produto'] : 'Produto'}</td>
                  <td>Valor Total (R$)</td>
              </tr>`;

    this.totalValue = 0;
    orcamento.itemsOrcamento.forEach((item, index) => {
      this.totalValue += (item.qtd ? item.qtd : 1) * item.valorUnitario;
      html += `
              <tr style="border-top: 1px solid #${corInfo}; line-height: 20px;">
                  <td style="min-width: 65px">${item.codigo}</td>
                  <td>${item.descProduto}</td>
                  <td style="min-width: 85px"></td>
              </tr>`;
    });

    html += `
      <tr style='background-color: ${corInfo}; line-height: 20px;'>
          <td></td>
          <td></td>
          <td>${this.formataDecimal(this.totalValue)}</td>
          <td></td>
      </tr>
      `;

    if (orcamento.desconto) {
      html += `
        <tr style='background-color: ${corInfo}; line-height: 20px;'>
            <td></td>
            <td style='text-align: right; padding-right: 10px'>Total com Desconto</td>
            <td>${this.formataDecimal(this.totalValue - orcamento.desconto)}</td>
            <td></td>
        </tr>
        `;

    }



    html += `<table>
    <br><br>`;

    return html;
  }

  public getItensTable(orcamento, corInfo, corTitulo, database) {
    this.tipoDaMoeda = orcamento.tipoMoeda;

    let currencyCode = 'BRL';
    if (orcamento.tipoMoeda == '$') {
      currencyCode = 'USD'
    }
    let html;
    html = `<table style="width: 100%; margin-top: -30px;">
              <tr style='background-color: ${corTitulo}; text-align: center; color: white;'>
                <td colspan="6" style="font-size: 16px;">${this.titulo2}</th>
              </tr>
              <tr style='background-color: ${corInfo}; line-height: 20px;'>
                <td>${this.translate.hasOwnProperty('Código') ? this.translate['Código'] : 'Código'}</td>
                <td>${this.translate.hasOwnProperty('Produto') ? this.translate['Produto'] : 'Produto'}</td>
                  <td>Qtd</td>
                  <td>Valor Unitário ${orcamento.tipoMoeda ? orcamento.tipoMoeda : 'BRL'} </td>
                  <td>Valor Total ${orcamento.tipoMoeda ? orcamento.tipoMoeda : 'BRL'} </td>
                  <td>Prazo Entrega</td>
              </tr>`;

    let totalQtd = 0;
    let totalValue = 0;
    orcamento.itemsOrcamento.forEach((item: any, index) => {
      totalQtd += item.qtd;
      totalValue += item.qtd * parseFloat(item.valorUnitario);
      html += `
              <tr style="border-top: 1px solid #${corInfo}; line-height: 20px; ">
                  <td style="min-width: 65px">${item.codigo}</td>
                  <td>${item.descProduto.replaceAll('\n', '<br>')}</td>
                  <td style="min-width: 45px; text-align: center">${item.qtd}</td>
                  <td style="min-width: 100px">${parseFloat(item.valorUnitario).toLocaleString('pt-br', { style: 'currency', currency: currencyCode })}</td>
                  <td style="min-width: 85px">${(item.qtd * parseFloat(item.valorUnitario)).toLocaleString('pt-br', { style: 'currency', currency: currencyCode })}</td>
                  <td style="min-width: 85px">${item.prazoEntrega} dias úteis</td>
              </tr>`;
      html += `
              <tr style="border-top: 1px solid #${corInfo}; line-height: 20px; min-height:40px">
                  <td style="min-width: 65px"><hr></td>
                  <td><hr></td>
                  <td style="min-width: 45px; text-align: center"><hr></td>
                  <td style="min-width: 100px"><hr></td>
                  <td style="min-width: 85px"><hr></td>
                  <td style="min-width: 85px"><hr></td>
              </tr>`;
    });

    if (database != 'horizon-studio') {
      html += `
        <tr style='background-color: ${corInfo}; line-height: 20px;'>
            <td colspan="2" style="text-align: right; padding-right: 30px;">Total</td>
            <td style="text-align: center">${totalQtd}</td>
            <td></td>
            <td>${totalValue.toLocaleString('pt-br', { style: 'currency', currency: currencyCode })}</td>
            <td></td>
        </tr>
        `;
    }

    if(orcamento.parcNumber && orcamento.parcNumber > 1 && orcamento.condicaoPag === 'À PRAZO') {
        let totalText = `${orcamento.parcNumber}x de ${parseFloat(( (totalValue - Number(orcamento.desconto)) /  orcamento.parcNumber ).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: currencyCode })}`;
        html += `
          <tr>
          <td colspan="3" style="text-align: right; padding-right: 30px;"></td>
          <td>${orcamento.desconto ? 'Desconto' : ''}</td>
          <td>${ orcamento.desconto ?orcamento.desconto.toLocaleString('pt-br', { style: 'currency', currency: currencyCode }) : ''}</td>
          <td></td>
          </tr>
          <tr style='background-color: ${corInfo}; line-height: 20px;'>
          <td colspan="3" style="text-align: right; padding-right: 30px;"></td>
          <td>Parcelamento</td>
          <td style="font-size:0.8rem; font-weight: bold;">${totalText}</td>
          <td></td>
          </tr>
        `;
      } else if(orcamento.desconto > 0) {
        html += `
          <tr>
          <td colspan="3" style="text-align: right; padding-right: 30px;"></td>
          <td>${orcamento.desconto ? 'Desconto' : ''}</td>
          <td>${ orcamento.desconto ?orcamento.desconto.toLocaleString('pt-br', { style: 'currency', currency: currencyCode }) : ''}</td>
          <td></td>
          </tr>
          <tr style='background-color: ${corInfo}; line-height: 20px;'>
          <td colspan="3" style="text-align: right; padding-right: 30px;"></td>
          <td>Valor com desconto</td>
          <td style="font-size:0.8rem; font-weight: bold;">${(totalValue - orcamento.desconto).toLocaleString('pt-br', { style: 'currency', currency: currencyCode })}</td>
          <td></td>
          </tr>
        `;
      }

    html += `<table>
    <br><br>`;

    return html;
  }

  public async export_html_pdf(orcamento, dadosCliente) {
    let database = this.userService.getDataBase();
    let PDFParam = await this.configService.loadEstimatePDFParam();
    let corInfo = PDFParam.color1;
    let corTitulo = PDFParam.color2;

    let userData = await this.userService.getUserFromDataBase();
    let imageData = await this.configService.loadLogo64();
    this.translate = await this.translateService.getTranslation('Dados do Pedido').toPromise();
    var NomeEmpresa1 = PDFParam.title;
    var NomeEmpresa2 = PDFParam.subtitle;
    var NomeEmpresa3 = PDFParam.subtitleThree;
    var Endereco1 = PDFParam.address1;
    var Endereco2 = PDFParam.address2;
    var Telefones = '';
    this.titulo1 = PDFParam.subImport1 ? PDFParam.subImport1 : 'Dados do Cliente';
    this.titulo2 = PDFParam.subImport2 ? PDFParam.subImport2 : 'Projeto';
    this.titulo4 = PDFParam.subImport4 ? PDFParam.subImport4 : 'Termos e Condições de venda';
    this.titulo3 = PDFParam.subImport3 ? PDFParam.subImport3 : 'Observações Gerais';
    var tituloItemOrcamento = '';
    if (database == 'agb') {
      //NomeEmpresa1 = 'AGB SUL COMPUTADORES E SERVIÇOS';
      // left / top / comprimento / altura

      //Endereco1 = 'Rua Amapá, 95, Sala 01 - Estados';
      //Endereco2 = 'Indaial / SC';


      tituloItemOrcamento = 'Dados do Pedido';
      Telefones = 'WhatsApp: (47) 9 8447 7797.';
    } else {
      if (database == 'dgsys') {

        //NomeEmpresa1 = 'DG - Sistemas Inteligentes';
        // left / top / comprimento / altura

        //Endereco1 = 'Rua Amapá, 95, Sala 01 - Estados';
        //Endereco2 = 'Indaial / SC';

        tituloItemOrcamento = this.translate.hasOwnProperty('Dados do Pedido') ? this.translate['Dados do Pedido'] : 'Dados do Pedido';
        Telefones = 'WhatsApp: (47) 9 8447 7797.';
      }      
    }

    const html = {
      name: '',
      html: '',
      headerHTML: '',
      footerHTML: '',
    };

    html.headerHTML =
      `
<style>
    span.logo {
      heigth: 90px;
      width: 90px;
      content: url(${imageData})
    }
</style>

<table style='width: 100%; webkit-print-color-adjust: exact;color:#000 !important; margin-top: 15px'>
  <tr>
      <td style="width: 25%; text-align: center">
        <span class="logo"></span>
      </td>
      <td style="width: 50%;">
          <div style="font-size: 13px;">${NomeEmpresa1}</div>
          ${NomeEmpresa2 ? ('<div style="font-size: 11px;" ngif>' + NomeEmpresa2 + '</div>') : ''}
          ${NomeEmpresa3 ? ('<div style="font-size: 9px;" ngif>' + NomeEmpresa3 + '</div>') : ''}           
          <div style="font-size: 9px;">${Endereco1}</div>
          <div style="font-size: 9px;">${Endereco2}</div>
      </td>
      <td style="width: 25%; font-size: 12px; text-align: center">`
    if (database != 'drrafael') {
      html.headerHTML += `# ${orcamento.codigo} #`;
    }

    html.headerHTML += `</td>
  </tr>
</table>
`;

    html.html =
      `
<html>
  <style>
      span.logo {
        heigth: 90px;
        width: 90px;
        content: url(${imageData})
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 11px;
      }

      .item-label-customer {
        background-color: ${corInfo};
        width: 68px;
        text-align: right;
        padding-right: 5px;
        border: 1px solid ${corInfo};
      }

      .item-data-customer {
        text-align: left;
        padding-left: 10px;
      }

  </style>
  <body>
    <div class='container'>
        <table style="width: 100%; ">
            <tr style='background-color: ${corTitulo}; text-align: center; color: white;'>
              <td colspan="6" style="font-size: 16px;">${this.titulo1}</th>
            </tr>

            <tr>
                <td class="item-label-customer">Cliente</td>
                <td class="item-data-customer">${dadosCliente.nomeFantasia}</td>
                <td class="item-label-customer">${dadosCliente.tipoPessoa == 'PF' ? 'CPF' : 'CNPJ'}</td>
                <td class="item-data-customer" colspan="2">${dadosCliente.tipoPessoa == 'PF' ? (dadosCliente.CPF ? dadosCliente.CPF : '') : dadosCliente.CNPJ ? dadosCliente.CNPJ : ''}</td>
            </tr>`;

    if (database != 'drrafael') {
      html.html += `<tr>
                    <td class="item-label-customer">Responsável</td>
                    <td class="item-data-customer">${orcamento.contatoCliente ? orcamento.contatoCliente : ''}</td>
                  </tr>`;

    }

    html.html += `<tr>
                <td class="item-label-customer">Data</td>
                <td class="item-data-customer">${moment(orcamento.dataGerado + '').format('DD/MM/YYYY')}</td>
                <td class="item-label-customer">Telefone</td>
                <td class="item-data-customer">${dadosCliente.telefone ? dadosCliente.telefone : ''}</td>
                <td class="item-label-customer">Celular</td>
                <td class="item-data-customer">${dadosCliente.celular ? dadosCliente.celular : ''}</td>
            </tr>
            <tr>
                <td class="item-label-customer">E-mail</td>
                <td class="item-data-customer" colspan="3">${orcamento.emailCliente ? orcamento.emailCliente : ''}</td>`;

    if (database == 'drrafael') {
      html.html += `<td class="item-label-customer">Status</td>
                    <td class="item-data-customer">${orcamento.status ? orcamento.status : ''}</td>`;
    }

    html.html += `</tr>
          </table>
        <br><br>`;

    if (database == 'drrafael') {
      html.html += this.getItensTableRafael(orcamento, corInfo, corTitulo, database);
    }

    if (database != 'drrafael') {
      html.html += this.getItensTable(orcamento, corInfo, corTitulo, database);
    }

    if (orcamento.statusDeadline === undefined) {
      orcamento.statusDeadline = '';
    }

    html.html +=
      `<table style="width: 100%; margin-top: -30px;">
            <tr style='background-color: ${corTitulo}; text-align: center; color: white;'>
              <td colspan="3" style="font-size: 16px;">${this.titulo3}</th>
            </tr>`;

    if (database != 'drrafael') {
      html.html +=
        `
              <tr style="line-height: 25px;">
                  <td colspan="2" style="text-align: right; padding-right: 20px;">Condição de pagamento</td>
                  <td>${orcamento.condicaoPag === 'À VISTA' ? orcamento.condicaoPag : orcamento.condicaoPag + ' - ' + orcamento.parcNumber + 'x'}</td>
              </tr>
              `;
    }

    html.html +=
      `
            <tr style="line-height: 25px;">
                <td colspan="2" style="text-align: right; padding-right: 20px;">Validade do orçamento</td>
                <td>${orcamento.validadeOrc} dias: válido até ${orcamento.dataValidade ? moment(orcamento.dataValidade + '').add(orcamento.validadeOrc, 'd').format('DD/MM/YYYY') : moment(orcamento.dataGerado + '').add(orcamento.validadeOrc, 'd').format('DD/MM/YYYY')}</td>
            </tr>`;

    // html.html +=
    //   `
    //         <tr style="line-height: 25px;">
    //             <td colspan="2" style="text-align: right; padding-right: 20px;">Tipo do orçamento</td>
    //             <td>${orcamento.tipoOrcamento} </td>
    //         </tr>`;

    if (database == 'drrafael') {
      orcamento.parcNumber ? orcamento.parcNumber : 1
      if (orcamento.parcNumber > 1) {
        html.html +=
          `             <tr style="line-height: 25px;">
                          <td colspan="2" style="text-align: right; padding-right: 20px;">Parcelamento</td>
                          <td>Em até ${(orcamento.parcNumber ? orcamento.parcNumber : 1)} vez(es) de ${this.formataDecimal(this.totalValue / (orcamento.parcNumber ? orcamento.parcNumber : 1))}</td>
                      </tr>
                      `;
      }
    }

    if (database === 'agb') {
      html.html += `
            <tr>
                <td colspan="2" style="text-align: right; padding-right: 20px;">Faturamento</td>
                <td> ${orcamento.faturamento}</td>
            </tr>
            <tr>
                <td colspan="2" style="text-align: right; padding-right: 20px;">Frete</td>
                <td> ${orcamento.tipoFrete}</td>
            </tr>
      `;
      if (orcamento.instalacao) {
        html.html += `
        <tr>
            <td colspan="2" style="text-align: right; padding-right: 20px;">Observações Gerais</td>
            <td> <span style="color: red">Serviços de instalação <strong>estão</strong> inclusos</span></td>
        </tr>
        `;
      } else {
        html.html += `
        <tr>
            <td colspan="2" style="text-align: right; padding-right: 20px;">Observações Gerais</td>
            <td> <span style="color: red">Serviços de instalação <strong>não</strong> estão inclusos</span></td>
        </tr>
         `;
      }
    }

    if (orcamento.observacao2) {
      html.html += `
            <tr>
                <td colspan="3" style="padding-left: 30px; padding-top: 20px; padding-right: 20px;">
                  <p style="white-space:pre-wrap; color: red">${orcamento.observacao2}</p>
                </td>
            </tr>
      `
    }

    html.html += `
        </table>

        <br><br>

        <table style="width: 100%; margin-top: -30px;">
            <tr style='background-color: ${corTitulo}; text-align: center; color: white;'>
              <td colspan="6" style="font-size: 16px;">${this.titulo4}</th>
            </tr>

            <tr style="text-align: justify; border-top: 10px solid #FFF; font-size: 10px">
                <td style="padding-left: 20px; padding-right: 20px">
                    <p style="white-space:pre-wrap;">
${PDFParam.condition}
                    </p>
                </td>
            </tr>
        </table>

        <br><br><br>

        <table style="width: 100%; margin-top: -80px;">
            <tr style="border-top: 10px solid #FFF; font-size: 10px">
                <td style="text-align: right; padding-right: 20px; width: 60%">
                  <span class="logo"></span>
                </td>
                <td style="width: 40%">
                  Deste já agradecemos a preferência<br>
                  ${userData.nome}<br>
                  ${Telefones}<br>
                  ${database != 'drrafael' ? userData.email : ''}<br>
                </td>
            </tr>
        </table>
    </div>
  </body>
</html>
`
    let footerTD = '';
    if (database == 'dgsys') {
      footerTD += `<td><span style="heigth: 15px; width: 15px; content: url(../../../../assets/images/dg-produtos/Gestão-de-Bens.jpg)"></span></td>`;
      footerTD += `<td><span style="heigth: 15px; width: 15px; content: url('../../../../assets/images/dg-produtos/Gestão-Fácil.jpg')"></span></td>`;
      footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/dg-produtos/Personalização-Fácil.jpg)"></td>`;
      footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/dg-produtos/Produção-Fácil.jpg)"></td>`;
      footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/dg-produtos/RH-Fácil.jpg)"></td>`;
    } else {
      if (database == 'agb') {
        footerTD += `<td"><img src="../../../../assets/images/agb-produtos/azure.png" style="width: 15px; height: 15px"></td>`;
        footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/agb-produtos/bkp.png)"></td>`;
        footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/agb-produtos/firewall.png)"></td>`;
        footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/agb-produtos/moni.png)"></td>`;
        footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/agb-produtos/office.png)"></td>`;
        footerTD += `<td style="heigth: 15px; width: 15px; content: url(../../../../assets/images/agb-produtos/powerbi.png)"></td>`;
      }
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    html.name = `Orçamento-${dadosCliente.nomeFantasia}-${orcamento.codigo}-${moment().format('DD-MM-YYYY')}`;
    html.html = btoa(encodeURIComponent(html.html));
    html.headerHTML = btoa(encodeURIComponent(html.headerHTML));
    html.footerHTML = btoa(encodeURIComponent(`
    <style>
      .page-footer{
        -webkit-print-color-adjust: exact;
        color:#9c9a9a !important;
        font-size:8px !important;
        width: 100%;
        text-align: center;
      }
    </style>
    <span class='page-footer'>Desenvolvido por DG Sistemas</span>`));

    const pdf = await this.http2
      .post(`https://punnekmvqtxqiscaem3nbev5s40jmhqg.lambda-url.us-east-1.on.aws/dev/getPDF?isMobile=true`, html, {
        headers
      }).toPromise() as any;

    if (localStorage.getItem('isMobile') === 'S') {
      window.parent.postMessage(pdf, '*');
    } else {
      window.open(pdf.url);
    }
  }

  //Motivos Recusa
  async salvarRefuse(refuse) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/orcamento/motivosRecusa/.json?auth=${token}`, refuse).toPromise();

  }

  async getAllRefuse() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/motivosRecusa/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.motivoRefuse = data;

    return this.motivoRefuse;
  }

  //Situção abertura
  async salvarOpen(abert) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/orcamento/opcaoAbertura/.json?auth=${token}`, abert).toPromise();

  }

  async getAllOpen() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/opcaoAbertura/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoAbertura = data;

    return this.opcaoAbertura;
  }

  //Situção abertura
  async salvarDeadline(abert) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/orcamento/opcaoPrazo/.json?auth=${token}`, abert).toPromise();

  }

  async getAllDeadline() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/opcaoPrazo/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoPrazo = data;

    return this.opcaoPrazo;
  }
}
