import { HttpConfigInterceptor } from './interceptor';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpModule } from '@angular/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { MessageService } from 'primeng/api';
import { FunctionsService } from './shared/services/utilsFunctions/functions.service';
import { LogService } from './shared/services/logs/log.service';
import { UserService } from "./shared/services/user/user-service.service";
import { TranslationService } from './shared/services/translation/translation.service';
import { ConfirmationDialogService } from './layout/confirmation-dialog/confirmation-dialog.service';
import { ConfirmationDialogComponent } from './layout/confirmation-dialog/confirmation-dialog.component';
import { ToastModule } from 'primeng/toast';


// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationService,
                //useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        HttpModule,
        ToastModule,
        NgbModule.forRoot()
    ],
    declarations: [
        AppComponent,
        ConfirmationDialogComponent,
    ],
    providers: [
        UserService,
        TranslationService,
        FunctionsService,
        LogService,
        AuthGuard,
        MessageService,
        ConfirmationDialogService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ConfirmationDialogComponent
    ]
})
export class AppModule {}
