export class Orcamento {

    public codigo: number = 0;
    public codigoCliente: number = 0;
    public contatoCliente: String = "";
    public emailCliente: String = "";
    public telefonCliente: String = "";
    public tipoPessoa: String = "";
    public celularCliente: String = "";

    public status: string = "Orçado"; // Orçado / Aprovado / Rejeitado / Refazer
    public statusReject: string = "";
    public statusOpen: string = "";
    public statusDeadline: string = "";
    public dataGerado: String = "";
    public dataAlteracao: string ="";
    public dataValidade: string ="";
    public validadeOrc: number = 0; // Em dias
    public condicaoPag: string = "À VISTA";
    public itemsOrcamento: any[] = [];
    public valorTotal: number = 0.0;
    public valorCusto: number = 0.0;
    public Observacao: String;
    public colaborador: String = '';
    public faturamento: String = 'AGB'; // Direto fornecedor / AGB
    public tipoFrete: String = 'FOB'; // FOB ou CIF
    public observacao: String = ''; // Acompanhamento
    public observacao2: String = '';
    public desconto: number = 0.0;
    public instalacao?:boolean;
    public parcNumber?:number;
    public tipoOrcamento?:string;

    public openAuto:boolean = false;
    
    constructor() {
    }

    getListaDeStatus() {
        return ['Orçado', 'Aprovado', 'Cancelado'];
    }
    getListaPagamento() {
        return ['À VISTA', 'À PRAZO']
    }

}
