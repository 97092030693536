import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Http } from '@angular/http';

import * as env from "../../../../environments/environment";
import * as moment from 'moment';

import { FunctionsService } from '../utilsFunctions/functions.service';
import { UserService } from '../user/user-service.service';
import { ConfigService } from '../config/config.service';
import { CacheService } from '../cache/cache.service';
import { Estoque } from '../../../classes/estoque';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ThrowStmt } from '@angular/compiler';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class StockService {
  private stockList = [];
  private vehicles = [];
  obsEstoque: AngularFireList<any>;
  stockCacheHash = '';

  constructor(public db: AngularFireDatabase,
    private functions: FunctionsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private cacheService: CacheService,
    private messageServ: MessageService,
    private http: Http) { }

  async getStockList() {
    if (this.stockList.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.stockType, this.stockCacheHash)) {
      let token = await this.functions.getUserToken();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/estoque/.json?auth=${token}`).toPromise();
      this.stockList = this.functions.convertObjToArray(data);

      this.stockList.sort((item1, item2) => {
        if (item1.desc > item2.desc) {
          return 1;
        } else {
          if (item1.desc < item2.desc) {
            return -1
          }
        }
        return 0;
      });

      this.stockCacheHash = await this.cacheService.getCacheHash(this.cacheService.stockType);
    }

    return this.stockList;
  }

  async getProductForCode(id) {
    let resp;
    await this.getStockList().then((item) => {
      resp = item.filter(rst => rst.id === id)
    });
    return resp["0"];
  }

  async getStockById(id): Promise<Estoque> {
    let dataStock: Estoque = undefined;
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/estoque/${id}/.json?auth=${token}`).toPromise();
    dataStock = JSON.parse(data['_body']);
    dataStock['id'] = id;

    return dataStock;
  }

  async removeStock(item) {
    if (confirm('Tem certeza que deseja remover o item "' + item.desc + '" do estoque?')) {

      this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/estoque/${item.id}`).remove();
      this.stockList = [];
      this.cacheService.invalidateCache(this.cacheService.stockType);
      return true;
    }

    return false;
  }

  async saveStockItem(item) {
    let total = item.qtd;
    if (item.qtdExternaLista) {
      for (let k = 0; k < item.qtdExternaLista.length; k++) {
        total += item.qtdExternaLista[k].qtdExterna
      }
    }
    if (item.qtdAlerta >= total) {
      if (item.emailSend !== true&& total != 0) {
        this.enviarEmail(item);
        item.emailSend = true;
      }
    } else {
      if (item.emailSend === true) {
        item.emailSend = false;
      }
    }
    await this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/estoque/${item.id}`).update(item);
    this.stockList = [];
    this.cacheService.invalidateCache(this.cacheService.stockType);
  }

  async saveOutputStockItem(item) {
    let total = 0;
    total += item.qtd;
    if (item.qtdExternaLista) {
      for (let k = 0; k < item.qtdExternaLista.length; k++) {
        total += item.qtdExternaLista[k].qtdExterna
      }
    }
    if (item.qtdAlerta >= total) {
      if (item.emailSend !== true && total != 0) {
        this.enviarEmail(item);
        item.emailSend = true;
      }
    } else {
      if (item.emailSend === true) {
        item.emailSend = false;
      }
    }
    await this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/estoque/${item.id}`).update(item);
    this.stockList = [];
    this.cacheService.invalidateCache(this.cacheService.stockType);
  }

  async addStockItem(item) {
    item.codigo = await this.configService.getNewLastStockCode();
    await this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/estoque`).push(item);
    this.stockList = [];
    this.cacheService.invalidateCache(this.cacheService.stockType);
  }

  message(msg, type = "warn", summary = "Atenção", time = 5000) {
    this.messageServ.add({
      severity: type,
      summary: summary,
      detail: msg,
      life: time,
    });
  }

  async enviarEmail(item) {
    let total = 0;
    let html = `
    <head>
    <style>
      td {
          padding: 5px;
      }

      .configGerais{
              font-size: 14px;
              width: 50%;
              text-align: center;
      }
      .configTabela{
          border-spacing: 0px;
          width: 100%;
          text-align: center;
          margin: 0px;
      }
      .tituloTop {
          padding: 15px;
          color: white;
          background-color:#0068a5;
          margin: 0;
      }
      .tituloBottom {
        padding: 15px;
        color: white;
        background-color:#0068a5;
        margin: 0;
        border-bottom-right-radius: 100%;
        border-bottom-left-radius: 100%;
    }
      .subtitulo {
          background-color:#0068a5;
          width: 100%;
          padding: 15px;
          text-align: center;
          color:white;

      }
      .itemDireita {
          text-align: left;
      }
      .itemEsquerda {
          text-align: right;
          font-weight: bold;
      }
      .checklistCima{

      }
      .checklistBaixo{

      }
      .reboot{
          background-color: #ff3c00e2;
          font-weight: bold;

      }
    </style>
    </head>

    <body class="configGerais">
      <h3 class="tituloTop">${item.desc}</h3>
      <hr style=" border:1px solid black; margin: 0;">
        <div>
          <br>
          <br>
          Abaixo está listado o detalhamento do estoque deste produto em sistema:
          <br>
          <hr>
          <h2 style="font-size: 14px">ESTOQUE INTERNO: ${item.qtd}</h2>
          <hr>

        `
    total += item.qtd;
    // if (item.qtdExternaLista.length > 0) {
    //   html += `<h2 style="font-size: 14px">ESTOQUE EXTERNO</h2>`
    //   for (let p = 0; p < item.qtdExternaLista.length; p++) {
    //     html += `RESPONSÁVEL/VEICULO: ${item.qtdExternaLista[p].tecnico} - QTD: ${item.qtdExternaLista[p].qtdExterna}
    //               <br>`
    //     total += item.qtdExternaLista[p].qtdExterna;
    //   }
    //   html += `<hr>
    //              <br>`
    // }

    html += `<h2 style="font-size: 14px">ESTOQUE TOTAL: ${total}</h2>
              <h2 style="font-size: 14px">ESTOQUE MÍNIMO: ${item.qtdAlerta}</h2>
              <hr>`

    html += `</div>
      <hr style=" border:px solid black; margin: 0;">
      <h3 class="tituloBottom">AGB Computadores</h3>
    </body>
    `;

    let email = 'dev@dgsys.com.br';
    let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
    let body = {
      from: `checklist@agbcomputadores.com.br`,
      email: email,
      message: html,
      subject: `ATENÇÃO! Estoque mínimo atigindo.`
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let options = { headers }
    await this.httpClient.post(url, body, options).toPromise()
      .then(() => {
        this.message("E-mail alerta estoque Enviado.", "success", "Sucesso", 3000);
      })
      .catch((error) => {
        alert("ERRO!");
      });

  }

  async exportExcel(list) {
    //import("xlsx").then(xlsx => {
        let data = [];
        await this.getVehicles().then((item) => { 
          let cars = item; 
          list.forEach((item, idx) => {
            !item.valorCusto ? item.valorCusto = 0 : item.valorCusto;
            !item.valorVenda ? item.valorVenda = 0 : item.valorVenda;
            !item.qtdExterna ? item.qtdExterna = 0 : item.qtdExterna;
            if (typeof(item.valorCusto) == 'string') {
              item.valorCusto = parseFloat(item.valorCusto);
            }
            if (typeof(item.valorVenda) == 'string') {
              item.valorVenda = parseFloat(item.valorVenda);
            }
            let prod: any = {
              "Código": item.codigo,
              "Descrição": item.desc,
              "Valor total": item.valorTotal,
              "Valor Compra": item.valorCusto,
              "Valor Venda": item.valorVenda,
              "Qtd. Int.": item.qtd,
              "Bancada": item.qtdBancada ? item.qtdBancada : 0
            }
            for (let index = 0; index < item.qtdExternaLista.length; index++) {
              prod[item.qtdExternaLista[index].tecnico] = item.qtdExternaLista[index].qtdExterna; 
            }
            for (let i = 0; i < cars.length; i++) {
              if (!prod[cars[i].placa]) {
                prod[cars[i].placa] = 0;
              }
            }
            if (item.qtdPorTecnicos) {
              for (let index = 0; index < item.qtdPorTecnicos.length; index++) {
                prod[item.qtdPorTecnicos[index].tecnico.nome ? item.qtdPorTecnicos[index].tecnico.nome : item.qtdPorTecnicos[index].tecnico] = item.qtdPorTecnicos[index].qtd; 
              }
            }
  
            data.push(prod)
          });
        });


        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer);
    //});
  }

  saveAsExcelFile(buffer: any): void {
      //import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, `Estoque-${moment().format('DD-MM-YYYY')}.xlsx`);
      //});
  }

  async printStock(list) {
    let showExternalStock = await this.configService.loadExternalStock();
    let printContents, popupWin;

    printContents = `<div class="table-responsive rounded-top">`
    printContents = printContents + `<table class="table table-sm table-hover">`
    printContents = printContents + `<thead class='thead-dark'>`
    printContents = printContents + `<tr>`
    printContents = printContents + `<th></th>`
    printContents = printContents + `<th>#</th>`
    printContents = printContents + `<th>Descrição</th>`
    printContents = printContents + `<th>Qtd. Int.</th>`
    if (showExternalStock) {
      printContents = printContents + `<th style="min-width:120px">Qtd. Ext.</th>`
    }
    printContents = printContents + `<th>Tipo</th>`
    printContents = printContents + `<th>Valor C. Uni</th>`
    printContents = printContents + `<th>Valor C. Total</th>`
    printContents = printContents + `<th>Valor V. Uni</th>`
    printContents = printContents + `<th>Valor V. Total</th>`
    printContents = printContents + `<th></th>`
    printContents = printContents + `</tr>`
    printContents = printContents + `</thead>`
    printContents = printContents + `<tbody>`;

    let totalIntQtd = 0;
    let totalExtQtd = 0;
    let valorCustoTotal = 0;
    let valorVendaTotal = 0;
    list.forEach((item) => {
      if (item.qtdExterna > 0 || item.qtd) {
        totalIntQtd += item.qtd;
        if (item.valorCusto === undefined) {
          item.valorCusto = 0
        }
        if (isNaN(item.qtdExterna) === true) {
          item.qtdExterna = 0;
        }
        totalExtQtd += (showExternalStock ? item.qtdExterna : 0);
        valorCustoTotal += (item.valorCusto * (item.qtd + (showExternalStock ? item.qtdExterna : 0)));
        valorVendaTotal += (item.valorVenda * (item.qtd + (showExternalStock ? item.qtdExterna : 0)));

        let tipo = item.unidade ? item.unidade : ' ';
        let contentLine = "<tr>";
        if (item.classFiscal) {
          contentLine += "<td width=22>**</td>";
        } else {
          contentLine += "<td></td>";
        }
        contentLine += "<td>" + item.codigo + "</td>";
        contentLine += "<td>" + item.desc + "</td>";
        contentLine += "<td>" + item.qtd + "</td>";
        // if (showExternalStock) {
        //   if (item.qtdExternaLista.length == 0) {
        //     contentLine += "<td>0</td>";
        //   } else {
        //     contentLine += "<td>";
        //     item.qtdExternaLista.forEach((item) => {
        //       contentLine += "<div>" + item.qtdExterna + " - " + item.tecnico + "</div>";
        //     });
        //     contentLine += "</td>";
        //   }
        // }

        contentLine += "<td>" + tipo + "</td>";
        contentLine += "<td>R$" + item.valorCusto.toFixed(2) + "</td>";
        contentLine += "<td>R$" + (item.valorCusto * (item.qtd + (showExternalStock ? item.qtdExterna : 0))).toFixed(2) + "</td>";
        contentLine += "<td>R$" + item.valorVenda.toFixed(2) + "</td>";
        contentLine += "<td>R$" + (item.valorVenda * (item.qtd + (showExternalStock ? item.qtdExterna : 0))).toFixed(2) + "</td>";
        contentLine += "</tr>";
        printContents = printContents + contentLine;
      }
    });

    printContents = printContents + `</tbody>`
    printContents = printContents + `<tfoot class='thead-dark'>`
    printContents = printContents + `<tr>`
    printContents = printContents + `<td></td>`
    printContents = printContents + `<td></td>`
    printContents = printContents + `<td>Total</td>`
    printContents = printContents + `<td>${totalIntQtd}</td>`
    if (showExternalStock) {
      printContents = printContents + `<td>${totalExtQtd}</td>`
    }
    printContents = printContents + `<td></td>`
    printContents = printContents + `<td></td>`
    printContents = printContents + `<td>R$${valorCustoTotal.toFixed(2)}</td>`
    printContents = printContents + `<td></td>`
    printContents = printContents + `<td>R$${valorVendaTotal.toFixed(2)}</td>`
    printContents = printContents + `</tr>`
    printContents = printContents + `</tfoot>`

    printContents = printContents + `</table>`
    printContents = printContents + `</div>`


    let pdfName = "Estoque - " + moment().format('DD-MM HHmm');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <title>${pdfName}</title>
          <style>
            //........Customized style.......
          </style>
        </head>
        <body onload="window.print();">
          ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  async saveVehicles(item) {
    await this.db.database.app.database(this.functions.getBaseURL()).ref(`/dados/dadosVeiculos/veiculos/${item.placa}`).update(item);
    this.vehicles = [];
    this.cacheService.invalidateCache(this.cacheService.stockType);
  }

  async getVehicles() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/dadosVeiculos/veiculos/.json?auth=${token}`).toPromise();
    this.vehicles = this.functions.convertObjToArray(data);

    return this.vehicles;
  }

  async getStockVehicles() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/dadosVeiculos/produtosUtil/.json?auth=${token}`).toPromise();
    let resp = this.functions.convertObjToArray(data);

    return resp;
  }

  async deleteVehicles(placa) {
    let token = await this.functions.getUserToken();
    await this.http.delete(`${this.functions.getBaseURL()}/dados/dadosVeiculos/veiculos/${placa}/.json?auth=${token}`).toPromise();
  }

  async deleteProdVehicles(placa) {
    let token = await this.functions.getUserToken();
    await this.http.delete(`${this.functions.getBaseURL()}/dados/dadosVeiculos/veiculos/${placa}/.json?auth=${token}`).toPromise();
  }

  async saveProducts(produtosUtil, veiculoServicoKey) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/dadosVeiculos/produtosUtil/${veiculoServicoKey}.json?auth=${token}`, produtosUtil).toPromise();
  }

  async saveProductById( veiculoServicoKey, prodUid ,produtosUtil ) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/dadosVeiculos/produtosUtil/${veiculoServicoKey}/${prodUid}.json?auth=${token}`, produtosUtil).toPromise();
  }

  async loadProducts(veiculoServicoKey) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/dadosVeiculos/produtosUtil/${veiculoServicoKey}.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async loadProductById(veiculoServicoKey,prodUid) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/dadosVeiculos/produtosUtil/${veiculoServicoKey}/${prodUid}.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async corrigeDados() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/dadosVeiculos/veiculos/.json?auth=${token}`).toPromise();
    this.vehicles = this.functions.convertObjToArray(data);

    this.vehicles.forEach(async(vel) => {
      let newProd = {};
      let prod:any = await this.loadProducts(vel.placa);
      for (let p = 0; p < prod.length; p++) {
        let obj = {
          codigo: prod[p].codigo,
          qtd: prod[p].qtdExternaLista ? prod[p].qtdExternaLista['0'].qtdExterna : 0,
          uid: prod[p].uid
        }
        newProd[prod[p].uid] = obj;
      }
      // await this.deleteProdVehicles(vel.placa);
      await this.saveProducts(newProd, vel.placa);
    });
  }
}
