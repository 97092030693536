import { Injectable } from '@angular/core';
import { FunctionsService } from '../utilsFunctions/functions.service';

import * as env from "../../../../environments/environment";
import { Http } from '@angular/http';
import { first } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserService } from '../user/user-service.service';
import { ApiService } from "../api/api.service";
import { User } from "../../../classes/user";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  listSituation = [];

  constructor(
    private db: AngularFireDatabase,
    private functions: FunctionsService,
    private userService: UserService,
    private http: Http,
    private api: ApiService) { }


  async loadSituation() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/situacoes/.json?auth=${token}`).toPromise();
    this.listSituation = this.functions.convertObjToArray(data);

    this.listSituation.sort((a,b) => {
      if (a.situacao < b.situacao) {
        return -1
      } else {
        if (a.situacao > b.situacao) {
          1
        } else {
          return 0
        }
      }
    })

    return Object.assign([], this.listSituation);
  }

  async addSituation(data) {
    let token = await this.functions.getUserToken();
    await this.http.post(`${this.functions.getBaseURL()}/dados/situacoes/.json?auth=${token}`, data).toPromise();
    this.listSituation = [];
  }

  public async removeSituation(item){
    if (item.value){
      let token = await this.functions.getUserToken();
      await this.http.delete(`${this.functions.getBaseURL()}/dados/situacoes/${item.value}.json?auth=${token}`).toPromise();
      this.listSituation = [];
    }
  }

  async getSituationById(id) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/situacoes/${id}/.json?auth=${token}`).toPromise();

    if (JSON.parse(data['_body'])) {
      data = JSON.parse(data['_body']);
      data['id'] = id;
      return data;
    }

    return undefined;
  }

  async getSituationByName(situacao){
    if (!this.listSituation) {
      await this.loadSituation();
    }
    for (let item of this.listSituation){
      if (situacao.toLowerCase() == item.situacao.toLowerCase()){
        return item;
      }
    }
    return undefined;
  }

  async situationWithValue(situacao){
    let situacoes = await this.loadSituation();
    for (let item of situacoes){
      if (situacao.toLowerCase() == item.situacao.toLowerCase()){
        if (item.valor && isNaN(item.valor)) {
          return +item.valor.replace(',', '.');
        } else {
          return item.valor;
        }
      }
    }
    return undefined;
  }

  private OvernightValue = undefined;
  private OvernightMinValue = undefined;
  private MinValue = undefined;
  private async loadDefaultValues() {
    if (this.OvernightValue == undefined ||
        this.OvernightMinValue == undefined ||
        this.MinValue == undefined) {

      let token = await this.functions.getUserToken();
      let base = this.userService.getDataBase();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/params-globais/.json?auth=${token}`).toPromise();

      if (JSON.parse(data['_body'])) {
        if (JSON.parse(data['_body']).valorPlantao) {
          this.OvernightValue = JSON.parse(data['_body']).valorPlantao.value;  }
        if (JSON.parse(data['_body']).valorMinPlantao) {
          this.OvernightMinValue = JSON.parse(data['_body']).valorMinPlantao.value; }
        if (JSON.parse(data['_body']).valorMinimo) {
          this.MinValue = JSON.parse(data['_body']).valorMinimo.value; }
      }
    }
  }
  async getOvernightDefaultValue() {
    await this.loadDefaultValues();
    return this.OvernightValue;
  }
  async getOvernightDefaultMinValue() {
    await this.loadDefaultValues();
    return this.OvernightMinValue;
  }
  async getDefaultMinValue() {
    await this.loadDefaultValues();
    return this.MinValue;
  }
  async saveDefaultValues(OvernightValue, OvernightMinValue, MinValue) {
    if (isNaN(MinValue)) { alert('Valor mínimo não informado.'); return }
    if (isNaN(OvernightValue)) { alert('Valor hora plantão não informado.'); return }
    if (isNaN(OvernightMinValue)) { alert('Valor mínimo plantãp  não informado.'); return }

    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/params-globais/valorMinimo/.json?auth=${token}`, {value: MinValue}).toPromise();
    this.http.patch(`${this.functions.getBaseURL()}/dados/params-globais/valorPlantao/.json?auth=${token}`, {value: OvernightValue}).toPromise();
    this.http.patch(`${this.functions.getBaseURL()}/dados/params-globais/valorMinPlantao/.json?auth=${token}`, {value: OvernightMinValue}).toPromise();
    this.OvernightValue = OvernightValue;
    this.OvernightMinValue = OvernightMinValue;
    this.MinValue = MinValue;
  }

  private lastOrderCode = 0;
  private async loadLastOrderCode() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoOS/.json?auth=${token}`).toPromise();

    this.lastOrderCode = JSON.parse(data['_body']).value;
    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoOS/.json?auth=${token}`, {value: this.lastOrderCode+1}).toPromise();
  }
  async getNewLastOrderCode() {
    await this.loadLastOrderCode();
    return this.lastOrderCode;
  }
  async saveNewLastOrderCode(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  private lastStockCode = 0;
  private async loadLastStockCode() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoEstoque/.json?auth=${token}`).toPromise();

    if (data['_body'] == "null") {
      this.lastStockCode = 1;
    } else {
      this.lastStockCode = JSON.parse(data['_body']).value;
    }

    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoEstoque/.json?auth=${token}`, {value: this.lastStockCode+1}).toPromise();
  }
  async getNewLastStockCode() {
    await this.loadLastStockCode();
    return this.lastStockCode;
  }

  private lastCustomerCode = 0;
  private async loadLastCustomerCode() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoCliente/.json?auth=${token}`).toPromise();

    if (data['_body'] == "null") {
      this.lastCustomerCode = 1;
    } else {
      this.lastCustomerCode = JSON.parse(data['_body']).value;
    }

    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoCliente/.json?auth=${token}`, {value: this.lastCustomerCode+1}).toPromise();
  }
  async getNewLastCustomerCode() {
    await this.loadLastCustomerCode();
    return this.lastCustomerCode;
  }

  private lastProviderCode = 0;
  private async loadLastProviderCode() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoFornecedor/.json?auth=${token}`).toPromise();

    if (data['_body'] == "null") {
      this.lastProviderCode = 1;
    } else {
      this.lastProviderCode = JSON.parse(data['_body']).value;
    }

    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoFornecedor/.json?auth=${token}`, {value: this.lastProviderCode+1}).toPromise();
  }
  async getNewLastProviderCode() {
    await this.loadLastProviderCode();
    return this.lastProviderCode;
  }

  private lastEstimateCode = 0;
  private async loadLastEstimateCode() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoOrc/.json?auth=${token}`).toPromise();

    if (data['_body'] == "null") {
      this.lastEstimateCode = 1;
    } else {
      this.lastEstimateCode = JSON.parse(data['_body']).value;
    }

    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/ultimoCodigoOrc/.json?auth=${token}`, {value: this.lastEstimateCode+1}).toPromise();
  }
  async getNewLastEstimateCode() {
    await this.loadLastEstimateCode();
    return this.lastEstimateCode;
  }

  headerDesc = '';
  async saveHeaderDesc(desc) {
    this.headerDesc = desc;
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    await this.http.patch(`${this.functions.getBaseURL()}/dados/utils/headerDesc/.json?auth=${token}`, {value: desc}).toPromise();
  }
  async loadHeaderDesc() {
    if (!this.headerDesc) {
      let token = await this.functions.getUserToken();
      let base = this.userService.getDataBase();

      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/utils/headerDesc/.json?auth=${token}`).toPromise();

      if (data['_body'] != "null") {
        this.headerDesc = JSON.parse(data['_body']).value
      }
    }

    return this.headerDesc ? this.headerDesc : 'Gestão de Serviços';
  }

  async saveLogo(image, type, height, width) {
    if (image) {
      let token = await this.functions.getUserToken();
      let base = this.userService.getDataBase();
      this.http.patch(`${this.functions.getBaseURL()}/dados/config/logo/.json?auth=${token}`, {base64: image, type: type, height: height, width: width}).toPromise();
    }
  }

  async loadLogoData() {
    return await this.api.get('/dados/config/logo/');
  }

  async loadLogo64() {
    let dataImage = await this.loadLogoData();
    if (dataImage) {
      return `data:${dataImage['type']};base64,${dataImage['base64']}`;
    } else {
      return '';
    }
  }

  async saveSortOpenedOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/sortOpenedOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadSortOpenedOS() {
    let resp = await this.api.get('/dados/config/sortOpenedOS/');
    return resp ? resp['value'] : 'Vencimento';
  }

  async saveExternalStock(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/externalStock/.json?auth=${token}`, {value: value}).toPromise();
  }

  
  async saveExternalMenu(value) {
    let token = await this.functions.getUserToken();
    if (value) {
      this.http.delete(`${this.functions.getBaseURL()}/dados/menuVisibility/carros/.json?auth=${token}`).toPromise();
    } else {
      this.http.patch(`${this.functions.getBaseURL()}/dados/menuVisibility/carros/.json?auth=${token}`, {value: value}).toPromise();
    }
  }

  async loadExternalStock() {
    let resp = await this.api.get('/dados/config/externalStock/');
    return resp ? resp['value'] : true;
  }

  async saveRequireEffort(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/RequireEffort/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadRequireEffort() {
    let resp = await this.api.get('/dados/config/RequireEffort/');
    return resp ? resp['value'] : true;
  }

  async saveUsePrudouct(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/UseProduct/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadUsePrudouct() {
    let resp = await this.api.get('/dados/config/UseProduct/');
    return resp ? resp['value'] : false;
  }

  async saveActivDueDate(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/ActivDueDate/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadActivDueDate() {
    let resp = await this.api.get('/dados/config/ActivDueDate/');
    return resp ? resp['value'] : false;
  }

  async saveTecViewOwnerOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/TecViewOwnerOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadTecViewOwnerOS() {
    let resp = await this.api.get('/dados/config/TecViewOwnerOS/');
    return resp ? resp['value'] : false;
  }

  async saveActivityrOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/saveActivityrOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadActivityrOS() {
    let resp = await this.api.get('/dados/config/saveActivityrOS/');
    return resp ? resp['value'] : false;
  }

  async saveIncomeWhenCloseOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/IncomeWhenCloseOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadIncomeWhenCloseOS() {
    let resp = await this.api.get('/dados/config/IncomeWhenCloseOS/');
    return resp ? resp['value'] : false;
  }

  async saveIncomeWhenApproveEstimateOpt(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/IncomeWhenApproveEstimate/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadIncomeWhenApproveEstimateOpt() {
    let resp = await this.api.get('/dados/config/IncomeWhenApproveEstimate/');
    return resp ? resp['value'] : false;
  }

  async saveProdFromStock(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/ProdFromStock/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadProdFromStock() {
    let resp = await this.api.get('/dados/config/ProdFromStock/');
    return resp ? resp['value'] : false;
  }

  async saveOpenNewWhenCloseOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/OpenNewWhenCloseOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadOpenNewWhenCloseOS() {
    let resp = await this.api.get('/dados/config/OpenNewWhenCloseOS/');
    return resp ? resp['value'] : false;
  }

  async saveOptQuestionActivityWhenSaveOS(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/QuestionActivityWhenSaveOS/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadQuestionActivityWhenSaveOS() {
    let resp = await this.api.get('/dados/config/QuestionActivityWhenSaveOS/');
    return resp ? resp['value'] : false;
  }

  async saveOptmultiSituation(value) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    this.http.patch(`${this.functions.getBaseURL()}/dados/config/multiSituation/.json?auth=${token}`, {value: value}).toPromise();
  }

  async loadmultiSituation() {
    let resp = await this.api.get('/dados/config/multiSituation/');
    return resp ? resp['value'] : false;
  }

  async saveMenuVisibility(base, menu, value) {    
    if (base == 'agb') {
      base = 'agb-computadores';
    }

    let token = await this.functions.getUserToken();
    if (!value) {
      await this.http.put(`https://${base}.firebaseio.com/dados/menuVisibility/${menu}.json?auth=${token}`, {value: false}).toPromise();
    } else {
      await this.http.delete(`https://${base}.firebaseio.com/dados/menuVisibility/${menu}.json?auth=${token}`).toPromise();
    }
  }


  menuVisibility = [];
  menuVisibilityBase = '';
  async getMenuVisibility(base, menu) {
    if (this.menuVisibilityBase != base && this.menuVisibility.length == 0) {
      let token = await this.functions.getUserToken();

      if (base == 'agb') {
        base = 'agb-computadores';
      }
      let data = await this.http.get(`https://${base}.firebaseio.com/dados/menuVisibility/.json?auth=${token}`).toPromise();
      this.menuVisibility = this.functions.convertObjToArray(data);
      this.menuVisibilityBase = base;
    }
    let returnValue = true;
    if (this.menuVisibility.length > 0) {
      this.menuVisibility.forEach((item) => {
        if (item.id == menu) {
          returnValue = false;
        }
      });
    }
    return returnValue;
    //return JSON.parse(data['_body']) ? JSON.parse(data['_body']).value : true;
  }

  async saveEstimatePDFParam(data) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();

    await this.http.put(`${this.functions.getBaseURL()}/dados/config/estimatePDF.json?auth=${token}`, data).toPromise();
  }

  async loadEstimatePDFParam() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/estimatePDF.json?auth=${token}`).toPromise();
    if (JSON.parse(data['_body'])) {
      return JSON.parse(data['_body'])
    } else {
      return {
        color1: '#e6e6ff',
        color2: '#003366',
        condition: "",
        title: "",
        subtitle: "",
        address1: "",
        address2: "",
        paymentCondition: ""
      }
    }
  }

  getNextDueDateFromActiv(activities) {
    if (activities && activities.length > 0) {
      activities = activities.filter(activ => {
        return activ.dataVencimentoDate;
      });

      let actAfterToday = activities.filter(activ => {
        return moment().isBefore(moment(activ.dataVencimentoDate));
      });
      if (actAfterToday.length == 0) {
        activities.sort((a,b) => {
          let dateA = new Date(a.dataVencimentoDate);
          let dateB = new Date(b.dataVencimentoDate);

          if (dateA < dateB) {
            return 1; } else {
          if (dateA > dateB) {
            return -1 }
          }
          return 0;
        });

        if (activities[0]) {
          return activities[0].dataVencimentoDate;
        }
      } else {

        return actAfterToday[0].dataVencimentoDate;
      }


    }

    return '';
  }

  async createNewDataBase(name) {
    let token = await this.functions.getUserToken();
    let exist = await this.http.get(`${this.functions.getBaseURL()}/${name}.json?auth=${token}`).toPromise();
    if (JSON.parse(exist['_body']) != null) {
      alert('Já existe uma base com este nome');
    } else {
      let xUsuario = new User();
      xUsuario.adm = true;
      xUsuario.master = true;
      xUsuario.tecnico = false;
      xUsuario.base = name;
      xUsuario.email = 'daniel.huebes@gmail.com';
      xUsuario.nome = 'Daniel Huebes';
      xUsuario.username = `dhuebes@${name}`;
      xUsuario.primeiroLogon = false;

      await this.http.patch(`${this.functions.getBaseURL()}/${name}/utils/ultimoCodigoOS/.json?auth=${token}`, {value: 0}).toPromise();
      //await this.saveNewLastOrderCode(0);
      await this.http.post(`${this.functions.getBaseURL()}/usuarios/.json?auth=${token}`, xUsuario).toPromise();


      alert('Base criada com sucesso.');
    }

  }

  async saveHoraComecoExpediente(data) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/horarioComecoExpediente.json?auth=${token}`, data).toPromise();
  }

  async getHoraComecoExpediente() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/horarioComecoExpediente.json?auth=${token}`).toPromise();
    return JSON.parse(data['_body'])
  }

  async saveHoraTerminoExpediente(data) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/horarioTerminoExpediente.json?auth=${token}`, data).toPromise();
  }

  async getHoraTerminoExpediente() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/horarioTerminoExpediente.json?auth=${token}`).toPromise();
    return JSON.parse(data['_body'])
  }

  async saveDiasDaSemanaTrabalhados(data){
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/diasDaSemanaTrabalhados.json?auth=${token}`, data).toPromise();
  }

  async getDiasDaSemanaTrabalhados() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/diasDaSemanaTrabalhados.json?auth=${token}`).toPromise();
    return JSON.parse(data['_body'])
  }

  async savePartsOfHourCalendar(data){
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/partsOfHourCalendar.json?auth=${token}`, data).toPromise();
  }

  async getPartsOfHourCalendar() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/partsOfHourCalendar.json?auth=${token}`).toPromise();
    return JSON.parse(data['_body'])
  }

  async saveTecStatus(data){
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/tecStatus.json?auth=${token}`, data).toPromise();
  }

  async getTecStatus() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/config/tecStatus.json?auth=${token}`).toPromise();
    return JSON.parse(data['_body'])
  }
}
